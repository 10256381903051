<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <div>
          <b-card-title>{{ `คำขอเชื่อมต่อระบบ ${total} รายการ` }}</b-card-title>
        </div>
      </b-card-header>
      <hr class="m-0">
      <b-card-body>
        <b-row align-h="center">
          <b-col>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="search"
                type="search"
                :placeholder="`ชื่อตัวแทน`"
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </b-card-body>
      <!-- Table -->
      <b-table
        :items="dataList"
        :fields="fields"
        :busy="isFetching"
        responsive
        show-empty
        hover
        selectable
        @row-selected="onRowSelected"
      >
        <template #cell(accountType)="data">
          {{ $displayAccountType(data.value) }}
        </template>

        <template #cell(environment)="data">
          <b-badge
            :variant="+data.value === 0?'warning': 'light-dark'"
            class="mr-1"
          >
            ระบบทดสอบ
          </b-badge>
          <b-badge
            :variant="+data.value === 1?'info': 'light-dark'"
            class="mr-1"
          >
            Production
          </b-badge>
        </template>

        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template #empty="">
          <p class="text-center text-muted">
            ไม่มีรายชื่อตัวแทน
          </p>
        </template>
      </b-table>

      <!-- pagination -->
      <b-card-footer>
        <b-row class="">
          <b-col md="4">
            <page-limit-select
              :value="limit"
              @update="onLimitChange"
            />
          </b-col>
          <b-col>
            <pagination-input
              :per-page="limit"
              :total="total"
              @update="(val) => (currentPage = val)"
            />
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {},
  data() {
    return {
      currentPage: 1,
      selectedLimit: 20,
      search: '',
      onSearchTimeout: null,
      fields: [
        {
          key: 'agentName',
          label: 'ชื่อตัวแทน',
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'website',
          label: 'เว็บไซต์เกม',
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'accountType',
          label: 'ระดับตัวแทน',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'commissionRate',
          label: '% รายได้จากค่าธรรมเนียม',
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'environment',
          label: 'ระบบ',
          thStyle: {
            minWidth: '250px',
          },
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.request.isGettingRequests,
    }),
    ...mapGetters(['requests']),
    dataList() {
      return this.requests
    },
    total() {
      return this.requests.length
    },
    pagination() {
      return {
        itemsPerPage: 0,
        totalItems: this.total,
      }
    },
    limit() {
      return this.pagination.itemsPerPage
    },
  },
  watch: {
    currentPage(val) {
      if (val) {
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    search() {
      this.delaySearch()
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['getRequests']),
    fetchData() {
      this.getRequests()
    },
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
    onLimitChange(limit) {
      if (limit) {
        this.selectedLimit = limit
        this.fetchData()
      }
    },
    onDelete() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((confirm) => {
          if (confirm) {
            //
          }
        })
    },
    onRowSelected(val) {
      this.$router.push(`/agents/detail/?id=${val[0].id}`)
    },
  },
}
</script>
